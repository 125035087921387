<template>
	<div class="form">
		<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: calc(100% - 24rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="分期计划名称">
<!--				<el-input v-model="form.plan_name" placeholder="请输入分期计划名称"></el-input>-->
				{{form.plan_name}}
			</el-form-item>
			<el-form-item label="选择分期商品">
				{{form.goods_name}}
<!--				<el-select v-model="form.goods_id" placeholder="请选择">-->
<!--					<el-option v-for="item in areaOptions" :label="item.goods_name" :value="item.id"></el-option>-->
<!--				</el-select>-->
			</el-form-item>
			<el-form-item label="总期数">
<!--				<el-input-number v-model="form.plan_num" :max="20" :min="2" style="width: 289rem"></el-input-number>-->
			{{form.plan_num}}
			</el-form-item>
			<el-form-item label="首次缴费金额占比">
<!--				<el-input-number v-model="form.first_percentage" :max="100" :min="1" style="width: 289rem"></el-input-number>-->
				{{form.first_percentage}}%
			</el-form-item>

			<h4 style="padding: 50rem 0 20rem 30rem">分期计划</h4>

			<div v-for="(item, index) in form.remain_percentage" :key="index" class="knowledge">
				<div class="flex" style="background: #f4f4f4;padding: 20rem;align-items: center">
					<div style="margin-right: 48rem;font-size: 14rem;color: #666">
						第{{ index + 2 }}期
					</div>
					<el-form-item label="金额占比" label-width="80rem" style="margin-bottom: 0">
						<el-input-number v-model="item.percentage" :max="100" :min="1" disabled></el-input-number>
						%
					</el-form-item>
					<el-form-item label="缴费截止日期" label-width="180rem" style="margin-bottom: 0">
						<el-date-picker v-model="item.end_pay_time" :picker-options="pickerOptions" disabled format="yyyy-MM-dd" placeholder="请选择缴费截止日期" type="date" value-format="yyyy-MM-dd"></el-date-picker>
					</el-form-item>
				</div>
<!--				<div class="flex" style="margin-left: 40rem">-->
<!--					<el-button v-if="form.remain_percentage.length !== 1" circle icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>-->
<!--					<el-button circle icon="el-icon-plus" @click="addKnowledge"></el-button>-->
<!--				</div>-->
			</div>
		</el-form>
		<div class="footer-button">
<!--			<el-button type="primary" @click="submit">保存</el-button>-->
			<el-button @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>

<script>

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
		return {
			backType: 0,
			form: {
				plan_name: '', // 分期计划名称，待填写
				goods_id: '', // 分期商品名称ID
				plan_num: 2, // 总期数
				first_percentage: '1', // 首次缴费金额占比
				remain_percentage: [{ // 剩余缴费金额占比与时间
					percentage: '', // 剩余缴费金额占比
					end_pay_time: '', // 剩余缴费截止时间
				}],
			},
			rules: {
				plan_name: [{required: true, message: '请输入分期计划名称'}],
				goods_id: [{required: true, message: '请选择分期商品名称'}],
				plan_num: [{required: true, message: '请输入总期数'}],
				first_percentage: [{required: true, message: '请输入首次缴费金额占比'}],
			},
			areaOptions: [],
			pickerOptions: {
				disabledDate: (date) => {
					const today = new Date();
					return date.getTime() < today.getTime() || date.getTime() === today.getTime();
				}
			}
		}
	},
	created() {
		this.$_register.get("/api/recruit/periodization-plan/goods").then(res => {
			this.areaOptions = res.data.data
		})
		this.$_register.get("/api/recruit/periodization-plan/show", {params: {id: this.$route.query.id}}).then(res => {
			this.form = res.data.data
		})
	},
	methods: {
		// 添加科目
		addKnowledge() {
			if (this.form.remain_percentage.length < this.form.plan_num - 1) this.form.remain_percentage.push({percentage: '', end_pay_time: ''})
			else this.$message.error('分期计划应小于分期总期数')
		},

		// 删除科目
		deleteKnowledge(index) {
			this.form.remain_percentage.splice(index, 1)
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					let params = {...this.form}
					this.$_register.post("/api/recruit/periodization-plan/edit", params).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.backType = 1
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.form {
	padding-top: 24rem;
}

.knowledge {
	margin-top: 24rem;
	display: flex;
	align-items: center;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
	}
}

.el-input-number {
	min-width: 263rem;
}
</style>
